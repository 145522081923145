/* .body_blue{
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    background-color: rgb(13 13 43/var(--tw-bg-opacity));
    color: rgb(255 255 255/var(--tw-text-opacity));
    font-family: Rubik;
    line-height: 1.5;
} */
/* 
@keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-100% * 6));
    }
  } */
  :root {
    overscroll-behavior: auto;
  }
  .grecaptcha-badge { 
    visibility: hidden !important;
}
.partners{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
a:-webkit-any-link:focus-visible {
  outline-offset: 0px!important;
}
:focus-visible {
  outline: none!important;
}
body {
  touch-action: manipulation!important;
}

.fly_button{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  padding-right: 15px;
  cursor: pointer;
}
.partner-item img{
  max-height: 100px;
}

@media (min-width: 1280px) {
  .xl-grid-cols-2-1 {
      grid-template-columns: 2fr 01fr 0fr;
  }
}

@media (min-width: 768px) {
  .md-grid-cols-2-1 {
      grid-template-columns: 2fr 01fr 0fr;
  }
}



.earncardinfo{
    grid-template-columns: 1fr 1fr 0fr;
    border: 1px solid #98989952;
    padding: 8px;
    border-radius: 0.5rem;
    box-shadow: 0 0 1px 0px #00000071;
}

.customHeader{
  transition: top 0.3s;
  position: fixed;
  width: 100%;
  top: 0;
}
.headerHide {
  top: -100%;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}